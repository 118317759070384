import React from 'react';
import '../styles/nav.scss';
import navContent from '../content/navi.json';

const SoraHeader = (props) => {
    return <nav className="navi">
        {navContent.slogan && <div className="navi__slogan">{navContent.slogan}</div>}
        {props.hasSplash && <img
            alt="logo"
            className={`navi__logo__splash ${props.isLoaded
            ? 'navi__logo__splash--loaded'
            : ''}`}
            src={navContent.logo}/>}
    </nav>;
}

export default SoraHeader;