import React, {useState} from "react"
import '../styles/classes.scss';
import classesContent from '../content/classes.json';
import 'aos/dist/aos.css';

const goTo = (sectionKey) => {
    if(typeof window !== 'undefined'){
        console.log(document.getElementById(sectionKey).offsetTop);
        window.scrollTo(0, document.getElementById(sectionKey).offsetTop);
    }
}

const ClassesPage = () => {
    const [activeSection,
        setActiveSection] = useState(null);
    return (
        <main id="classes" className="classes">
            <h1 className="classes__title">{classesContent.pageTitle}</h1>
            {classesContent.navigations.length > 0 && <ul className="classes__navigations">{classesContent
                    .navigations
                    .map((navigation, index) => <li
                        key={index}
                        className={`classes__navigations__item ${navigation.sectionKey === activeSection
                        ? 'classes__navigations__item--active'
                        : ''}`}>
                        <button onClick={() => goTo('section-' + navigation.sectionKey)}>{navigation.sectionName}</button>
                    </li>)}</ul>}
            {classesContent.navigations.length > 0 && classesContent
                .navigations
                .map((navigation, index) => {
                    if (typeof classesContent[navigation.sectionKey] !== 'undefined') {
                        const sectionContent = classesContent[navigation.sectionKey];
                        return <section
                            id={`section-${navigation.sectionKey}`}
                            className={`classes__section classes__section--${navigation.sectionKey}`}>
                            <h2 className="classes__section__title" dangerouslySetInnerHTML={{__html: sectionContent.title}}></h2>
                            {sectionContent.subtitle && <h3 className="classes__section__subtitle" dangerouslySetInnerHTML={{__html: sectionContent.subtitle}}></h3>}
                            {sectionContent.classes && sectionContent.classes.length > 0 && <ul className="classes__section__classes">{sectionContent
                                    .classes
                                    .map((item, index) => {
                                        return <li
                                            key={index}
                                            className={`classes__section__classes__item ${typeof item.extraClass !== 'undefined'?item.extraClass:''}`}>
                                            {item.image && <div class="classes__section__classes__item__image"><img src={item.image}/></div>}
                                            {item.title && <div
                                                class="classes__section__classes__item__title"
                                                dangerouslySetInnerHTML={{
                                                __html: item.title
                                            }}></div>}
                                            {item.subtitle && <div
                                                class="classes__section__classes__item__subtitle"
                                                dangerouslySetInnerHTML={{
                                                __html: item.subtitle
                                            }}></div>}
                                            {item.description && <div
                                                class="classes__section__classes__item__description"
                                                dangerouslySetInnerHTML={{
                                                __html: item.description
                                            }}></div>}
                                            {item.zh_subtitle && <div
                                                class="classes__section__classes__item__zh_subtitle"
                                                dangerouslySetInnerHTML={{
                                                __html: item.zh_subtitle
                                            }}></div>}
                                            {item.zh_description && <div
                                                class="classes__section__classes__item__zh_description"
                                                dangerouslySetInnerHTML={{
                                                __html: item.zh_description
                                            }}></div>}
                                        </li>;
                                    })}</ul>}
                        </section>
                    } else {
                        return null;
                    }
                })}
        </main>
    )
}

export default ClassesPage