import React from 'react';
import '../styles/footer.scss';
import footerContent from '../content/footer.json';

const SoraFooter = (props) => {
    return <footer className={`footer ${props.isLoaded?'loaded':''}`}>
        {Array.isArray(footerContent.social_networks) && <ul className="footer__social">{
            footerContent.social_networks.map((network, index) => {
                return <li key={index}><a class={`footer__social__icon footer__social__icon--${network.name}`} href={network.link}><span>{network.name}</span></a></li>
            })
        }</ul>}
        <div className="footer__content">
            {footerContent.copyright && <div className="footer__copyright">{footerContent.copyright.replaceAll('[[year]]', (new Date()).getFullYear())}</div>}
            {footerContent.address && <div className="footer__address"><a href={footerContent.address.link} target="_blank" rel="noreferrer">{footerContent.address.text}</a></div>}
            {footerContent.whatsapp && <div className="footer__whatsapp"><a href={footerContent.whatsapp.link}>{footerContent.whatsapp.text}</a></div>}
        </div>
    </footer>;
}

export default SoraFooter;