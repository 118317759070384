import React, {useCallback, useEffect, useState} from "react"
import SoraHeader from "../components/SoraHeader";
import '../styles/index.scss';
import landingContent from '../content/homepage.json';
import SoraFooter from "../components/SoraFooter";
import {Helmet} from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';

// markup
const IndexPage = () => {
    const ratio = landingContent.landing.image.width / landingContent.landing.image.height;
    const [lockedScreen,
        setLockedScreen] = useState(true);
    const [splasHeight,
        setSplashHeight] = useState(0);
    const [height,
        setHeight] = useState(0);
    const [isLoaded,
        setIsLoaded] = useState(false);
    const updateWindowDimensions = useCallback(() => {
        const windowRatio = window.innerWidth / window.innerHeight;
        setHeight(window.innerHeight);
        if (ratio > windowRatio) {
            setSplashHeight(window.innerHeight);
        } else {
            setSplashHeight(window.innerWidth / ratio);
        }
        console.log("updating height");
    }, [ratio]);
    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        setIsLoaded(true);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [ratio, updateWindowDimensions]);
    useEffect(() => {
        if (isLoaded && lockedScreen) {
            setTimeout(() => {
                AOS.init({duration: 1500});
                AOS.refresh();
                setLockedScreen(false);
            }, 5000);
        }
    }, [isLoaded, lockedScreen]);
    const seo = landingContent.seo;
    return (
        <main
            id="homepage"
            className={`homepage ${ !lockedScreen
            ? 'unlocked'
            : ''}`}>
            {seo && <Helmet>
                {seo.charset && <meta charSet={seo.charset}/>}
                {seo.title && <title>{seo.title}</title>}
                {seo.title && <meta property="og:title" content={seo.title}/>}
                {seo.url && <meta property="og:url" content={seo.url}/>}
                {seo.canonical && <link rel="canonical" href={seo.canonical}/>}
                {seo.description && <meta name="description" content={seo.description}/>}
                {seo.description && <meta property="og:description" content={seo.description}/>}
                {seo.image && <meta name="image" content={seo.image}/>}
                {seo.image && <meta property="og:image" content={seo.image}/>}
                <meta name="twitter:card" content="summary_large_image"/> {seo.title && <meta name="twitter:title" content={seo.title}/>}
                {seo.description && <meta name="twitter:description" content={seo.description}/>}
                {seo.image && <meta name="twitter:image" content={seo.image}/>}
            </Helmet>}
            <section
                id="homepage__landing"
                className={`homepage__landing ${isLoaded
                ? 'loaded'
                : ''}`}
                style={{
                height: `${height}px`
            }}>
                <div
                    className="homepage__landing__image"
                    style={{
                    height: `${splasHeight}px`,
                    backgroundImage: `url(${landingContent.landing.image.path})`
                }}></div>
                {Array.isArray(landingContent.landing.buttons) && <div className="homepage__landing__buttons">{landingContent
                        .landing
                        .buttons
                        .map((button, index) => {
                            return <a
                                rel="noreferrer"
                                target="_blank"
                                href={button.url}
                                key={index}
                                className="homepage__landing__buttons__button">{button.title}</a>
                        })}</div>}
            </section>
            <section
                className={`homepage__about ${isLoaded
                ? 'loaded'
                : ''}`}>
                {landingContent.about.title && <h2
                    data-aos="fade-up"
                    className="homepage__about__title"
                    dangerouslySetInnerHTML={{
                    __html: landingContent.about.title
                }}></h2>}
                {landingContent.about.summary && <div
                    data-aos="fade-up"
                    className="homepage__about__summary"
                    dangerouslySetInnerHTML={{
                    __html: landingContent.about.summary
                }}></div>}
                {landingContent.about.detail && <div
                    data-aos="fade-up"
                    className="homepage__about__detail"
                    dangerouslySetInnerHTML={{
                    __html: landingContent.about.detail
                }}></div>}
            </section>
            <section
                className={`homepage__classes ${isLoaded
                ? 'loaded'
                : ''}`}>
                {landingContent.classes.title && <h3 data-aos="fade-up" className="homepage__classes__title">{landingContent.classes.title}</h3>}
                {landingContent.classes.tagline && <div
                    data-aos="fade-up"
                    className="homepage__classes__tagline"
                    dangerouslySetInnerHTML={{
                    __html: landingContent.classes.tagline
                }}></div>}
                {Array.isArray(landingContent.classes.list) && <ul className="homepage__classes__list">{landingContent
                        .classes
                        .list
                        .map((item, index) => {
                            return <li data-aos="fade-up" className="homepage__classes__list__item" key={index}>
                                {item.image && <div className="homepage__classes__list__item__image"><img
                                    alt={item.title
                                    ? item.title
                                    : ''}
                                    src={item.image}
                                    width="830"
                                    height="830"/></div>}
                                <div className="homepage__classes__list__item__content">
                                    {item.title && <span className="homepage__classes__list__item__title">{item.title}</span>}
                                    {item.cta && <a href={item.cta.url} className="homepage__classes__list__item__cta">{item.cta.title}</a>}
                                </div>
                            </li>
                        })}</ul>}
            </section>
            <section
                className={`homepage__schedule ${isLoaded
                ? 'loaded'
                : ''}`}>
                {landingContent.schedule.title && <h3 data-aos="fade-up" className="homepage__schedule__title">{landingContent.schedule.title}</h3>}
                <div className="homepage__schedule__content">
                    {landingContent.schedule.tagline && <div
                        data-aos="fade-up"
                        className="homepage__schedule__tagline"
                        dangerouslySetInnerHTML={{
                        __html: landingContent.schedule.tagline
                    }}></div>}
                    {Array.isArray(landingContent.schedule.cta) && <ul className="homepage__schedule__list">{landingContent
                            .schedule
                            .cta
                            .map((cta, index) => {
                                return <li data-aos="fade-up" key={index}>
                                    <a href={cta.url} target="_blank" rel="noreferrer">
                                        <span>{cta.title}</span>
                                    </a>
                                </li>
                            })}</ul>}
                </div>
            </section>
            <section
                className={`homepage__commitment ${isLoaded
                ? 'loaded'
                : ''}`}>
                <div className="homepage__commitment__content">
                    <div className="homepage__commitment__left">
                        {landingContent.commitment.title && <h4 data-aos="fade-up" className="homepage__commitment__title">{landingContent.commitment.title}</h4>}
                        {landingContent.commitment.tagline && <div
                            data-aos="fade-up"
                            className="homepage__commitment__tagline"
                            dangerouslySetInnerHTML={{
                            __html: landingContent.commitment.tagline
                        }}></div>}
                    </div>
                    {Array.isArray(landingContent.commitment.cards) && <ul className="homepage__commitment__list">{landingContent
                            .commitment
                            .cards
                            .map((card, index) => {
                                return <li data-aos="fade-up" className="homepage__commitment__list__item" key={index}>
                                    {card.title && <div
                                        className="homepage__commitment__list__item__title"
                                        dangerouslySetInnerHTML={{
                                        __html: card.title
                                    }}></div>}
                                    {card.content && <div
                                        className="homepage__commitment__list__item__content"
                                        dangerouslySetInnerHTML={{
                                        __html: card.content
                                    }}></div>}
                                    {card.remark && <div
                                        className="homepage__commitment__list__item__remark"
                                        dangerouslySetInnerHTML={{
                                        __html: card.remark
                                    }}></div>}
                                </li>
                            })}</ul>}
                </div>
            </section>
            <SoraHeader hasSplash={true} isLoaded={isLoaded}/>
            <SoraFooter isLoaded={isLoaded}/>
        </main>
    )
}

export default IndexPage
